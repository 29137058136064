import { Component, Input } from '@angular/core';
import { assetURL } from 'src/environments/environment';
import { navigationState } from '@citizens/mfe-shared-state'
import { catchError, forkJoin, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ViewMoreNotificationsModalComponent } from '../view-more-notifications-modal/view-more-notifications-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationService } from 'src/app/services/app.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() id: string = "";
  @Input() isMobileApp = false;
  @Input() fullName: string = "";
  @Input() fullNameFromSession: string = "";
  @Input() links: any;
  // @ViewChild('notifications', {static: true}) notifications: ElementRef;
  public credentials: any[] = [];
  public notificationData = [];
  isDropdownOpen: boolean = false;
  public selectedCompanyId: string = '';
  public notificationsList: any = [];
  public selectedNotifications: any= [];
  public totalNotifications: number= 0;
  public displaySpinner: boolean = false;
  public displayNotificationBtn: boolean = false;
  public currentCompanyId: any;


  get assetURL() {
    return assetURL;
  }


  constructor(private https: HttpClient,
    private modalService: NgbModal,
    private appService: ApplicationService
  ) {}

  ngOnInit() {
    
    if (!this.id) {
      this.id = "dashboard-header";
    }
    const credStore = sessionStorage.getItem('credData');

    if (credStore) {
      this.fullName = JSON.parse(credStore).userDisplayName
      this.displaySpinner = true;
      this.credentials = JSON.parse(credStore).results ?? [];
      // TODO: Testing purpose
      // const mock =  this.credentials[0];
      // mock.accountLogin = "SURESHG_UATALL_MMGPS";
      // mock.applicationName = "accessoptima";
      // mock.applicationStatus = "ACTIVE";
      // mock.compId = "UATALL";
      // mock.lastLogin = "";
      // mock.legacyApplicationId = "MMGPS";
      // mock.legacyUserNm = "accessoptima";
      // mock.underMaintenance = false;
      // mock.userId = "SURESHG_UATALL";
      // this.credentials.push(mock);

      // let notificationsDataMock = this.notificationsData({'SSO_ID':cred?.accountLogin}).pipe(catchError(error => of(error)));
      let accessOptimaCredentials = this.credentials.filter(cred => cred.applicationName.toLowerCase() === 'accessoptima');

      let getAllNotifications = accessOptimaCredentials.map(cred => this.appService.getNotificationsData({ 'SSO_ID': cred?.accountLogin }).pipe(catchError(error => of(error))));
      // getAllNotifications.push(notificationsDataMock);
      forkJoin(getAllNotifications).subscribe((notificationRes: any) => {
        this.displaySpinner = false;
        accessOptimaCredentials.forEach((cred, index) => {
          const count = (notificationRes[index]?.data?.length || 0);
          if (!this.selectedCompanyId && count) {
            this.selectedCompanyId = `${cred.compId}-${cred.userId}`;
          }
          this.displayNotificationBtn = true;
          if (count !== 0) {
            this.notificationsList.push({
              count: count,
              compId: `${cred.compId}-${cred.userId}`,
              notifications: notificationRes[index]?.data ?? []
            });
          }
          this.totalNotifications += count;
        });
        this.displayNotifications({ currentTarget: { value: this.selectedCompanyId } } as never);
      });

    }
    
  }

  profile() {
    navigationState.setNavigation('profile/view')
  }

  manageCredentials() {
    navigationState.setNavigation('credentials/manage')
  }

  openUniversalLogin() {
    console.log('digital butler');
    const openButlerEvent = new CustomEvent("open-butler", {
      detail: { from: "header"}
    });
    dispatchEvent(openButlerEvent);
  }

  
  displayNotifications(event: any) {
    if (event?.currentTarget) {
      this.currentCompanyId = event?.currentTarget.value
      const credNotif = this.notificationsList.find((cred: any) => cred.compId === event?.currentTarget.value);
      if (credNotif) {
        this.selectedNotifications = credNotif.notifications;
      }
    }
  }

  viewMoreNotifications() {
    const cred = this.credentials.find((cred, index) => `${cred.compId}-${cred.userId}` === this.selectedCompanyId);
    const selectSsoId = { ssoId: cred.accountLogin, companyId: cred.compId, userId: cred.userId, applicationName: cred.applicationName }
    const modalRef = this.modalService.open(ViewMoreNotificationsModalComponent, { size: 'sm', centered: true });
    modalRef.componentInstance.notificationData = this.selectedNotifications;
    modalRef.componentInstance.selectedCredData = selectSsoId;
  }


  public openApplication() {
    const cred = this.credentials.find((cred, index) => `${cred.compId}-${cred.userId}` === this.currentCompanyId);
    const selectSsoId = { ssoId: cred.accountLogin, companyId: cred.compId, userId: cred.userId, applicationName: cred.applicationName }
    const openAOEvent = new CustomEvent("open-access-optima", {
      detail: { from: "btl-application", ssoIdData: selectSsoId }
    });
    dispatchEvent(openAOEvent);
  }

  logout() {
    sessionStorage.removeItem('banner_preference');
    sessionStorage.removeItem('isSMBUser');
    sessionStorage.clear();
    localStorage.clear()
    navigationState.setNavigation('uportal/logout/universal')
  }

  ngAfterViewInit() {

  }
}
