import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { assetURL } from 'src/environments/environment';

@Component({
  selector: 'app-view-more-notifications-modal',
  templateUrl: './view-more-notifications-modal.component.html',
  styleUrls: ['./view-more-notifications-modal.component.css']
})
export class ViewMoreNotificationsModalComponent {
  @Input() notificationData: any;
  @Input() selectedCredData: any;

  constructor(private ngbModal: NgbModal
  ) {
  }

  get assetURL() {
    return assetURL;
  }

  ngOnInit(): void {
    console.log(this.notificationData);
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  openApplication() {
    const openAOEvent = new CustomEvent("open-access-optima", {
      detail: { from: "btl-application", ssoIdData: this.selectedCredData }
    });
    dispatchEvent(openAOEvent);
    this.ngbModal.dismissAll();
  }

}
