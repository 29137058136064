<div class="headerContainer">
    <nav class="expand-expand-lg bgImage" [ngStyle]="{'background-image': 'url('+assetURL+'assets/back1.jpeg)'}">
        <div class="float-right userProfiles">
            <img src="{{assetURL}}assets/notification.png" alt="Notification" style="height: 30px; width: 30px; margin: 5px;">
            <img src="{{assetURL}}assets/user.png" alt="Notification" style="height: 30px; width: 30px; margin: 5px;">

        </div>
        <div class="welcomeUsername float-left">
            <h3>
                {{salutation}} {{fullName !== undefined ? fullName : fullNameFromSession }}
            </h3>
        </div>
    </nav>

</div>