import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class ApplicationService {
    constructor(private https: HttpClient) {}

    getNotificationsData(data: any): Observable<any> {
        const url = environment.notificationsUrl;
        return this.https.get(url, { headers: data });
    }
}