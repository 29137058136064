import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { DynamicComp } from './directives/dynamic-comp.directive';
import { PrivateBankingHeaderComponent } from './components/private-banking-header/private-banking-header.component';
import { HttpClientModule } from '@angular/common/http';
import { ViewMoreNotificationsModalComponent } from './components/view-more-notifications-modal/view-more-notifications-modal.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DynamicComp,
    PrivateBankingHeaderComponent,
    ViewMoreNotificationsModalComponent,
    LoadingSpinnerComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule
   ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
