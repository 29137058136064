<div class="modal-header more-notifications">
    <h4 class="modal-title">Available Notifications</h4>
    <span class="cbds-c-iconButton remove-popup cbds-c-iconButton--contained cbensightenevent" aria-label="close"
        cbdata-type="button" cbdata-reason="message-modal-cross-close" (click)="closeModal()">
        <svg class="cbds-c-icon close-icon" focusable="false">
            <use href="../../../assets/cbds-icons-ui.svg#close" xlink:href="../../../assets/cbds-icons-ui.svg#close"></use>
        </svg>
    </span>
</div>
<div class="modal-body notifications-body">
    <ul aria-labelledby="userName" class="">
        <ng-container *ngFor="let item of notificationData; let i = index">
            <li class="notifications-count" *ngIf="item.count !== 0" (click)="openApplication()">
                <span  class="count">{{ item.count }}</span>
                <p  class="notification-subject" [innerHTML]="item.subject"></p>
            </li>
        </ng-container>
    </ul>
</div>
